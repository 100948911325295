import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true // Include cookies in requests
});

// Function to refresh the access token
let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = () => {
  refreshSubscribers.forEach(callback => callback());
  refreshSubscribers = [];
};

const addRefreshSubscriber = callback => {
  refreshSubscribers.push(callback);
};

// Response interceptor to handle 401 errors (unauthorized) and refresh tokens
apiClient.interceptors.response.use(
  response => response, // Pass through if no errors
  async error => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // If a refresh is already in progress, wait for it to complete
      if (isRefreshing) {
        return new Promise(resolve => {
          addRefreshSubscriber(() => {
            resolve(apiClient(originalRequest)); // Retry the original request once token is refreshed
          });
        });
      }

      isRefreshing = true;

      try {
        // Call the refresh endpoint (cookies are automatically included)
        await axios.post(
          `${baseURL}/auth/refresh-token`,
          {},
          { withCredentials: true }
        );

        // Once the access token is refreshed, retry the original request
        isRefreshing = false;
        onRefreshed();
        return apiClient(originalRequest); // Retry the original request
      } catch (refreshError) {
        isRefreshing = false;
        console.error("Refresh token expired or invalid. Logging out...");
        window.location.href = "/login"; // Redirect to login page on token expiration
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
