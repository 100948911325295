// cookies.js

/**
 * Get a cookie by name.
 * @param {string} cookieName - The name of the cookie to retrieve.
 * @returns {string|null} - The value of the cookie or null if not found.
 */
export const getCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie); // Decode cookie string
    const cookieArray = decodedCookie.split(';'); // Split into individual cookies
  
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length); // Return the value
      }
    }
    return null;
  };
  
  /**
   * Set a cookie with name, value and options.
   * @param {string} cookieName - The name of the cookie.
   * @param {string} cookieValue - The value of the cookie.
   * @param {Object} [options] - Optional settings (expires, path, etc.).
   */
  export const setCookie = (cookieName, cookieValue, options = {}) => {
    const { days = 7, path = '/', domain = '', secure = false } = options;
  
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Set expiration
      expires = `; expires=${date.toUTCString()}`;
    }
  
    const domainString = domain ? `; domain=${domain}` : '';
    const secureString = secure ? '; secure' : '';
  
    document.cookie = `${cookieName}=${encodeURIComponent(cookieValue)}${expires}; path=${path}${domainString}${secureString}`;
  };
  
  /**
   * Delete a cookie by name.
   * @param {string} cookieName - The name of the cookie to delete.
   */
  export const deleteCookie = (cookieName) => {
    setCookie(cookieName, '', { days: -1 }); // Set the expiration date in the past
  };
  
  /**
   * Check if a cookie exists.
   * @param {string} cookieName - The name of the cookie.
   * @returns {boolean} - True if the cookie exists, otherwise false.
   */
  export const hasCookie = (cookieName) => {
    return getCookie(cookieName) !== null;
  };
  