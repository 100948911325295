import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Resume from "./Resume";
import LoginForm from "./LoginForm.js";
import Dashboard from "./Dashboard.js";
import Navbar from "../components/Navbar";
import Callback from "./Callback";
import "./App.css";
import ProtectedRoute from "../components/ProtectedRoute.js";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check for token in localStorage on initial render
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <div className="app">
        {/* Pass isLoggedIn and setIsLoggedIn to Navbar */}
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute setIsLoggedIn={setIsLoggedIn}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/resume" element={<Resume />} />
          <Route
            path="/login"
            element={
              <LoginForm
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute setIsLoggedIn={setIsLoggedIn}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
