// /api/authApi.js
import apiClient from "./apiClient";

const authApi = {
  login: async (email, password) => {
    const response = await apiClient.post("/auth/login", { email, password });
    return response.data;
  },

  register: async (email, password, firstName, lastName) => {
    const response = await apiClient.post("/auth/register", {
      email,
      password,
      firstName,
      lastName
    });
    return { data: response.data, status: response.status };
  },

  refreshToken: async refreshToken => {
    const response = await apiClient.post("/auth/refresh-token", {
      refreshToken
    });
    return response.data;
  },

  verifySession: async () => {
    const response = await apiClient.get("/auth/verify-session");
    return response.data;
  },

  logout: async () => {
    return apiClient.post("/auth/logout");
  }
};

export default authApi;
