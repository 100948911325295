import React, { useState, useEffect, useRef } from "react";
import GetNowListening from "../metrics/GetNowListening.js";
import GetMyTopArtists from "../lists/GetMyTopArtists.js";
import GetMyTopTracks from "../lists/GetMyTopTracks.js";
import GetMyTopGenres from "../lists/GetMyTopGenres.js";
import StreamGraphGenre from "../charts/StreamGraphGenre.js";
import BarTracksByDecade from "../charts/BarTracksByDecade.js";
import "./Dashboard.css";
import { getCookie } from "../utils/cookies.js";
import { useNavigate } from "react-router-dom";
import chevLeft from "../images/chevronLeftWhite.png";
import chevRight from "../images/chevronRightWhite.png";
import { GetTotalSpinTime } from "../metrics/GetTotalSpinTime.js";

function Dashboard() {
  const navigate = useNavigate();
  const [isSfyConnected, setIsSfyConnected] = useState(null);
  const hasCheckedSession = useRef(false); // Prevent duplicate calls

  // State for current visible component in metrics and chart container
  const [currentMetricIndex, setCurrentMetricIndex] = useState(0);
  const [currentChartIndex, setCurrentChartIndex] = useState(0);

  // Define the components for lists and charts
  const listsComponents = [
    { component: <GetMyTopArtists />, ref: useRef(null) },
    { component: <GetMyTopTracks />, ref: useRef(null) },
    { component: <GetMyTopGenres />, ref: useRef(null) }
  ];

  const chartComponents = [

    { component: <StreamGraphGenre />, ref: useRef(null) },
    { component: <BarTracksByDecade />, ref: useRef(null) },
  ];

  // Handlers to scroll through lists and charts
  const handleNextMetric = () => {
    setCurrentMetricIndex((prevIndex) => (prevIndex + 1) % listsComponents.length);
  };

  const handlePrevMetric = () => {
    setCurrentMetricIndex((prevIndex) => (prevIndex - 1 + listsComponents.length) % listsComponents.length);
  };

  const handleNextChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex + 1) % chartComponents.length);
  };

  const handlePrevChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex - 1 + chartComponents.length) % chartComponents.length);
  };

  useEffect(() => {
    if (!hasCheckedSession.current) {
      const cookieString = getCookie("sessionUser");

      if (!cookieString) {
        navigate("/login");
      } else {
        const user = JSON.parse(cookieString);
        setIsSfyConnected(user.isSfyConnected);
      }

      hasCheckedSession.current = true;
    }
  }, [navigate]);

  const handleSfyLink = async () => {
    const scopes = [
      "user-read-private",
      "user-read-email",
      "user-library-read",
      "user-read-recently-played",
      "playlist-read-private",
      "user-read-currently-playing",
      "playlist-read-collaborative",
      "user-top-read"
    ];
    const redirectUri = `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_SPOTIFY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_SPOTIFY_REDIRECT_URI}&scope=${scopes.join(" ")}`;
    window.location.href = redirectUri;
  };

  if (isSfyConnected === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard">
      {!isSfyConnected ? (
        <div className="link-spotify">
          <p>Your account has not been linked with Spotify yet.</p>
          <button onClick={handleSfyLink}>Connect</button>
        </div>
      ) : (
        <div className="content">

          <div className="metrics-container">
            <div className="user-container">
              <h2>Now Spinning:</h2>
              <GetNowListening />
            </div>
            <div className="spin-time">
              <GetTotalSpinTime />
            </div>
          </div>

          <div className="lists-container">
            <div className="header-buttons">
              <button onClick={handlePrevMetric}><img src={chevLeft} alt="Previous" /></button>
              <button onClick={handleNextMetric}><img src={chevRight} alt="Next" /></button>
            </div>
            <div className="lists-display">
              <div ref={listsComponents[currentMetricIndex].ref}>
                {listsComponents[currentMetricIndex].component}
              </div>
            </div>
          </div>

          <div className="chart-container" id="chart-container">
            <div className="header-buttons">
              <button onClick={handlePrevChart}><img src={chevLeft} alt="Previous" /></button>
              <button onClick={handleNextChart}><img src={chevRight} alt="Next" /></button>
            </div>
            <div className="chart-display">
              <div ref={chartComponents[currentChartIndex].ref}>
                {chartComponents[currentChartIndex].component}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
