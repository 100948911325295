import React, { useEffect, useState } from "react";
import sfyApi from '../api/sfyApi';
import './GetNowListening.css';


//LFM get now listening
export const GetNowListening = () => {
    const [sfyData, updateSfyData] = useState([]);
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const getNowListening = async () => {
            try {
                const data = await sfyApi.getCurrentPlaying(); // Fetch now playing track from the backend
                updateSfyData(data);
                setError(null); // Reset error state on successful fetch
            } catch (err) {
                console.error("Error fetching now playing:", err);
                setError("Whoops! Something went wrong with Spotify API.");
            }
        };

        // Call immediately and set an interval to fetch every 10 seconds
        getNowListening();
        const intervalId = setInterval(getNowListening, 20000); // 10000ms = 10 seconds

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array to run only on mount/unmount

    const buildNowListening = () => {

        if (error) {
            return <p>{error}</p>;
        }

        if (!sfyData || !sfyData.name) {
            return (
                <div>
                    <p>Not playing music right now....</p>
                    <a href="https://open.spotify.com" target="_blank" rel="noopener noreferrer" className="jams-link">
                        Play some jams!
                    </a>
                </div>
            );
        }

        const trackName = sfyData.name;
        const artistNames = sfyData.artists.map(artist => artist.name).join(', ');
        const imageUrl = sfyData.album.images[2].url;

        return (
            <div className="now-listening-container" >
                <div className='listening-container'>
                    <img
                        src={imageUrl}
                        alt={trackName}
                        className="listening-image"
                    />
                    <div className="listening-text">
                        <p>{trackName}</p>
                        <p>{artistNames}</p>
                    </div>
                </div>

            </div >
        );
    };

    return buildNowListening();
};

export default GetNowListening;