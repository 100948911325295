import React, { useEffect, useState, useRef } from "react";
import sfyApi from '../api/sfyApi';
import { getCookie } from "../utils/cookies";
import './GetTotalSpinTime.css';



//LFM get now listening
export const GetTotalSpinTime = () => {
    const [sfyData, updateSfyData] = useState([]);
    const [error, setError] = useState(null); // Error state
    const hasFetched = useRef(false); // UseRef to track if the request has already been made
    const msToHourConversion = 3600000;

    useEffect(() => {
        const getTotalSpinTime = async () => {
            const cookieString = getCookie("sessionUser");
            const user = JSON.parse(cookieString);
            try {
                const data = await sfyApi.getTotalSpinTime(user.email, "2024-06-01T00:00:00Z", new Date());
                updateSfyData(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching now playing:", err);
                setError("Whoops! Something went wrong with Spotify API.");
            }
        }

        if (!hasFetched.current) {
            getTotalSpinTime();
            hasFetched.current = true; // Mark as fetched to prevent duplicate calls
        }
    }, []); // Empty dependency array to run only on mount/unmount

    const buildSpinTime = () => {

        if (error) {
            return <p>{error}</p>;
        }

        if (!sfyData) {
            return <p>No Spins found</p>;
        }

        const spinHours = Math.round((sfyData.spin_time / msToHourConversion) * 100) / 100;

        return (
            <div className="spin-time-container" >

                <h2>Total Spin Time:</h2>

                <h1>{spinHours} hours</h1>

            </div >
        );
    };

    return buildSpinTime();
};

export default GetTotalSpinTime;