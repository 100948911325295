import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import apiClient from "../api/apiClient";
import whiteDog from "../images/dogWhite.png";
import authApi from "../api/authApi";
import "./Navbar.css";

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(
    () => {
      const checkSession = async () => {
        try {
          await authApi.verifySession(); // Call backend to check if the session is still valid
          setIsLoggedIn(true);
        } catch (error) {
          console.log("User is not authenticated", error);
          setIsLoggedIn(false); // Set to false if the session has expired or is invalid
        }
      };

      if (window.location.pathname !== "/login") checkSession();
    },
    [setIsLoggedIn]
  );

  const handleLogout = async () => {
    try {
      await apiClient.post("/auth/logout", {
        token: localStorage.getItem("refreshToken")
      });

      setIsLoggedIn(false);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/dashboard" className="navbar-links">
          <img src={whiteDog} alt="SpinPuppy" className="spin-puppy-logo" />
          <h3>SpinPuppy</h3>
        </Link>
      </div>
      <div className="navbar-right">
        {isLoggedIn
          ? <button className="auth-button" onClick={handleLogout}>
            Logout
          </button>
          : location.pathname !== "/login" &&
          <button className="auth-button" onClick={() => navigate("/login")}>
            Login
          </button>}
      </div>
    </nav>
  );
};

export default Navbar;
