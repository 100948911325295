// /api/sfyApi.js
import apiClient from "./apiClient";

const sfyApi = {
  auth: async () => {
    const response = await apiClient.get("/spotify/auth");
    return response.data;
  },

  callback: async (code, email) => {
    const response = await apiClient.post("/spotify/callback", {
      code,
      email
    });
    return response.data;
  },

  myPlaylists: async email => {
    const response = await apiClient.get("/spotify/me/playlists", {
      params: email
    });
    return response.data;
  },

  myTopArtists: async (email, startAt, endAt) => {
    const response = await apiClient.get("/spotify/me/top-artists", {
      params: { email, startAt, endAt }
    });
    return response.data;
  },

  myTopGenres: async (email, startAt, endAt) => {
    const response = await apiClient.get("/spotify/me/top-genres", {
      params: { email, startAt, endAt }
    });
    return response.data;
  },

  myTopTracks: async (email, startAt, endAt) => {
    const response = await apiClient.get("/spotify/me/top-tracks", {
      params: { email, startAt, endAt }
    });
    return response.data;
  },


  getCurrentPlaying: async () => {
    const response = await apiClient.get("/spotify/me/currently-playing");
    return response.data;
  },

  getGenreStream: async (email) => {
    const response = await apiClient.get("/spotify/charts/genre-stream", { email });
    return response.data;
  },

  getTracksByDecade: async (email, startAt, endAt) => {
    const response = await apiClient.get('/spotify/charts/track-count-by-decade', {
      params: { email, startAt, endAt }
    });
    return response.data;
  },

  getTotalSpinTime: async (email, startAt, endAt) => {
    const response = await apiClient.get('/spotify/me/total-spin-time', {
      params: { email, startAt, endAt }
    });
    return response.data;
  }
}

export default sfyApi;
