import { useEffect } from "react";
import authApi from "../api/authApi";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ isLoggedIn, setIsLoggedIn, children }) => {
  const navigate = useNavigate();

  useEffect(
    () => {
      const checkSession = async () => {
        try {
          await authApi.verifySession();
          setIsLoggedIn(true);
        } catch (error) {
          console.error("User is not authenticated", error);
          navigate("/login");
        }
      };

      checkSession();
    },
    [setIsLoggedIn, navigate]
  );

  if (!isLoggedIn) {
    // navigate("/login"); // Avoid rendering protected content if not logged in
  }

  return children;
};

export default ProtectedRoute;
